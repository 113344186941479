  
import SocketClient from '../Socket';
import WebChatStore from '../WebChat.Store';
import Icon from './Icon';
import MemberCardItem from './MemberCardItem';
import './Message.css'; 
import ProductItem from './ProductItem'; 
function Message(props) {
  let userImage = '';

  const openDetail = () => {
    window.showMessageDetail(props.item);
  }
  const getLoadingContentItem = () => {
    return <div className="message-loading">
 
      <img style={{width:'50px', height:'30px'}} src={'/loading.svg'} />
    </div>
  }
  const getTypingContentItem = () => {
    return <div className="message-loading">
      Typing 
      <img style={{width:'50px', height:'30px'}} src={'/typing.svg'} />
    </div>
  }
  const getContentItem = () => {
    let content = props.item.content;
    console.log(content);
    
    if(props.item.type=='text'){
      
        content = (props.item.content || '').toString();
      content=content.replace(/\b(\w{30,})\b/g, function(match) {
        return match.slice(0, 30) + '-' + match.slice(30);
      })      
      .replace(/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\//g, '/////////// ///////////')
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/<[^>]+>/g, '')
      .replace(/((http:|https:)[^\s]+[\w])/g, '<a href="$1" target="_blank">$1</a>')
      .replace(/\[\#(.*?)\]/g, '<u class="subject">#$1</u>')
      .replace(/\[\$(.*?)\]/g, '<u class="subject">$1</u>')
      .replace(/\r\n|\r|\n/g, '<br />')
      .replace(/<br \/>/g, '<br /><br />')
       
    }

 


    return <>
    {props.item.type=='text' &&  <div dangerouslySetInnerHTML={{__html:content}}  onClick={()=>props.onMessageClicked(props.item)} ></div>} 
    {props.item.type=='product' &&  <ProductItem item={props.item.content} onClick={()=>props.onMessageClicked(props.item)} />} 
    {(props.item.type || '').toLowerCase()=='video' && <div className='message-item-video'>
                                    {props.item.uploading && 
                                    <div className='message-image-upload-progress'>
                                       <div className='message-image-upload-progress-text'>Uploading...</div>
                                      <div className='message-image-upload-progress-bar' style={{width:((props.item.uploadProgress || 0)*70/100+30)+'%'}}>{(props.item.uploadProgress || 1)+'%'}</div>
                                    </div>
                                    }
                                    <video className='message-video rounded clickable' controls src={SocketClient.getImageUrl(props.item.content)} onClick={()=>props.onMessageClicked(props.item)}></video>
                                 </div>}
    {props.item.type=='image'  && <div className='message-item-image'>
                                    {props.item.uploading && 
                                    <div className='message-image-upload-progress'>
                                       <div className='message-image-upload-progress-text'>Uploading...</div>
                                      <div className='message-image-upload-progress-bar' style={{width:((props.item.uploadProgress || 0)*70/100+30)+'%'}}>{(props.item.uploadProgress || 1)+'%'}</div>
                                    </div>
                                    } 
                                    <img className='message-image rounded clickable' src={SocketClient.getImageUrl(props.item.content)} onClick={()=>props.onMessageClicked(props.item)} />
                                 </div>} 
    {props.item.type=='article' && <img src={props.item.content} onClick={()=>props.onMessageClicked(props.item)} />} 
    {props.item.type=='membercard' && <MemberCardItem onClick={openDetail} item={props.item.content} />} 
    </>
  }

  if(props.item.type=='SYSTEM'){
    return  null;
    }
  let user = WebChatStore.getCurrentUser() || {};
  return (
    <div className={"message  " + (props.item.loading?' loading ': '  ') + (props.item.sender_id==user.id?' user ': ' csr ') + (props.mode=='csr'?' csr ': 'customer-service') }>
        <div className={"sender-image " } style={{backgroundImage:'url('+(props.item.sender_id==user.id?userImage:'/csr.png')+')'}}></div>
        <div className="sender-name">{(props.item.sender_id==user.id?'You':'Customer Service')}</div>
       
        
        {props.item.time && <div className="sender-desc">{new Date(props.item.time || 0).toLocaleTimeString()}</div>}
        <div className={'content rounded padding '+(props.item.type || '').toLowerCase()}>
         
         {props.item.loading?getLoadingContentItem():getContentItem()}
         {props.item.typing?getTypingContentItem():null}
         <div className={"message-status  " + (props.item.status || '').toLowerCase()}>
            <Icon name="checkmark" className="check" />
            <Icon name="checkmark" className="check" /> 
            <Icon name="warning" className="warning"  /> 
            {/* <Icon name="spinner11"    />  */}
            <img style={{width:'20px', height:'20px'}} className="sending " src={'/loading.svg'} />

            <Icon name="spinner11" className="failed clickable" onClick={()=> props.onMessageResend(props.item)} /> 
        </div>
         
        </div>  
    </div>
  );
}

export default Message;
