

const config = {
  // SOCKET_URL: 'http://localhost:3135',
  // UPLOAD_URL:'http://localhost:3136/upload',
  // FILE_URL:'http://localhost:3136',

  SOCKET_URL: 'https://api.liaotian.shopnowcloud.cn',
  UPLOAD_URL:'https://file.liaotian.shopnowcloud.cn/upload',
  FILE_URL:'https://file.liaotian.shopnowcloud.cn',
}

export default config;